<template>
  <div class="create-container">
    <single-form />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import SingleForm from "../../../components/setting/SingleForm";
export default {
  name: "index",
  components: { SingleForm },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "تەڭشەك باشقۇرۇش", route: "create" },
      { title: "يېڭىدىن قوشۇش" }
    ]);
  }
}
</script>
